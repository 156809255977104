@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 100;
	font-stretch: 100%;
	font-display: swap;
	src: url('./fonts/OpenSans.ttf') format('truetype');
}

@font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 100;
	font-stretch: 100%;
	font-display: swap;
	src: url('./fonts/OpenSans-Italic.ttf') format('truetype');
}
