// pagination styling
.pagination {
	.page-item.active {
		.page-link {
			background-color: $--standard-sub;
			color: white;
			border-radius: 3px;
		}
	}

	.page-item {
		margin: 0 3px;
		.page-link {
			border-radius: 3px;
			background-color: white;
			color: $--standard-grey;
			font-size: 12px;
		}
	}
}

	.dark.pagination {
		.page-item.active {
			.page-link {
				background-color: $--standard-sub;
				color: white;
				border-radius: 100%;
			}
		}

		.page-item {
			margin: 0 3px;
			.page-link {
				border-radius: 100%;
				background-color: rgba(255, 255, 255, 0.178);
				color: white;
				font-size: 12px;
			}
		}
	}
