// dark theme, styles applied to dark wrapper theme
// please code split this as soon as possible
.page-container-wrapper.dark {

	background-color: $--standard-bg;
	color: white;

	.small-container {
		max-width: 70%;
	}

	.smaller-container {
		max-width: 50%;
	}

	.full-container {
		max-width: 100%;
	}

	h4 {
		font-weight: bold;
	}

	.basic-page {
		padding: 20px 0;
	}

	.item-img {
		border-radius: 10px;
		max-height: 80px;
		height: 100%;
		max-width: 100%;
	}

	.col-title {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 700;
		font-size: 11px;
		width: 100%;
		padding: 10px 0;
		background-color: $--standard-grey;
		color: white;
	}

	.form-control {
		color: white;
		background-color: $--standard-grey;
		border: none;
	}

	.form-control::placeholder,
	.form-control:focus {
		color: rgb(136, 135, 135);
	}

	.btn {
		font-size: inherit;
	}

	.btn-warning,
	.btn-warning-r:hover {
		background: $--standard-warning;
		color: $--standard-grey;
		border: none;
		transition: 0.1s;
	}
	.btn-warning:hover,
	.btn-warning-r {
		color: $--standard-warning;
		border: none;

		background-color: $--standard-grey;

		&.outline {
			border: 1px solid $--standard-warning;
		}
	}

	.btn-primary,
	.btn-success,
	.btn-primary-r:hover,
	.btn-success-r:hover,
	.form-control-file {
		background: $--standard-sub;
		color: $--standard-grey;
		border: none;
		transition: 0.1s;
	}
	.btn-primary:hover,
	.btn-success:hover,
	.btn-primary-r,
	.btn-success-r {
		color: $--standard-sub;
		border: none;

		background-color: $--standard-grey;

		&.outline {
			border: 1px solid $--standard-sub;
		}
	}

	.btn-primary:active,
	.btn-success:active {
		color: $--standard-grey !important;
		background-color: $--standard-sub !important;
		border: none;
	}

	.btn-removeCross {
		color: $--standard-grey;
		transition: 0.1s;
		background-color: $--standard-sub;
		border: none;
		font-size: 15px;
		width: 30px;
		height: 30px;
	}

	.btn-removeCross:hover {
		color: $--standard-sub;
		background-color: $--standard-grey;
	}

	a:hover {
		text-decoration: none;
	}

	a {
		color: white;
	}

	.darkTheme-table {
		border-collapse: separate;
		border-spacing: 2px;

		th {
			border: none;
			color: #c7c7c7;
		}

		tr {
			border: none;
			color: white;
		}

		tbody tr {
			margin: 10px 0;

			th {
				background-color: $--standard-lightgrey;
			}
		}



		tbody td {
			background-color: $--standard-lightgrey;
			border-top: none;
			vertical-align: middle;

			&.td-nostyle {
				background-color: rgba(0, 0, 0, 0) !important;
			}
		}
	}

	.customer-info-holder {
		background-color: $--standard-lightgrey;
		padding: 15px;
		display: flex;
		flex-direction: column;

		strong {
			margin-bottom: 4px;
		}
	}

	.customer-info-holder-title {
		font-weight: bold;
		font-size: 17px;
	}

	.nav-tabs {
		border: none;

		.nav-link {
			transition: 0.1s;
			border: none;
			border-radius: 0;
			background: theme-color("background");
		}

		.nav-link.active {
			background-color: $--standard-sub;
			color: black;
		}

		.nav-link.active:hover {
			background-color: $--standard-sub;
			color: white;
		}

		.nav-link:hover {
			background-color: $--standard-sub;
			color: white;
		}
	}

	.nav-pills .nav-link.active {
		color: black;
	}

	.select__control {
		background-color: $--standard-grey;
		border: none;
		box-shadow: none;
	}

	.select__control--menu-is-open, .select__menu {
		background-color: $--standard-lightgrey;
	}

	.select__single-value, .select__input, .select__option {
		color: white;
	}

	.select__option--is-focused {
		background-color: $--standard-lightergrey;
	}

	.select__option--is-selected {
		background-color: $--standard-sub;
		color: black;
	}

	.select__input-container {
		color: inherit;
	}

	.highlightedRow {
		background-color: $--standard-grey;
		margin: 0 0 5px;
		min-height: calc(80px + 2em);
		border-left: 3px solid $--standard-sub;
		transition: border-width 0.1s linear;
		padding: 1em 0;

		&.small {
			width: 60%;
		}

		&.odd {
			background-color: $--standard-greyblue;
		}

		>div {
			display: flex;
			justify-content: center;
			align-items: center;

			input {
				text-align: center;
			}
		}

		&.highlighted {
			background-color: #000;
		}
	}

	.highlightedRow:hover {
		background-color: $--standard-darkgreyblue;
	}

	.highlightedElement {
		border-left: 3px solid $--standard-sub;
	}

	.highlightedRow:hover {
		border-width: 10px;
	}

	.lines {
		display: flex;
		justify-content: center;

		.form-control {
			border: 2px solid $--standard-sub;
		}
	}

	.lines-form-group {
		max-width: 390px;
	}

	@media only screen and (max-width: 480px) {
		.row {
			border-radius: 0;
		}
	}

	.list-group-item {
		background-color: inherit;
	}

	.input-group-text {
		font-size: inherit;
	}

	.react-dropdown-tree-select a .tag {
		color: black;
	}
}
// end of dark theme
