@import "custom";
@import "~bootstrap/scss/bootstrap";
@import "./pagination.scss";
@import "./darkTheme.scss";
@import "./fonts.scss";

body {
	font-family: 'Open Sans', sans-serif;
	background-color: $--standard-bg;
	font-size: 0.8125em;
}

.spooky {
	opacity: 0.5;
}

.unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.item-img {
	object-fit: contain !important;
}

.crumb:hover {
	text-decoration: underline !important;
}

.vh-100 {
	min-height: 100vh;
}

.vw-100 {
	min-width: 100vw;
}

.m-0 {
	margin: 0;
}

.color-block {
	width: 1em;
	height: 1em;
	display: inline-block;
	vertical-align: text-bottom;
	border-radius: 0.25em;
	box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.5);
}

.type-form:hover {
	border-color: white;
}

.type-form {
	border-style: solid;
	border-width: 2px;
	border-radius: 4px;
	border-color: $--standard-lightgrey;
}

.pim-collapsed {
	overflow: hidden;
	width: 0;
	height: 0;
	display: block;
}

.cart-bar {
	display: inline-block;
	position: fixed;
	padding: 1em 0.75em 0.75em 1em;
	right: 0;
	bottom: 0;
	background-color: $--standard-grey;
	color: white;
}

.sub-tile {
	height: 150px;
	.icon {
		max-height: 110px;
		max-width: 100%;
	}
}

.navbar {
	background-color: $--standard-sub;
	color: black;
}

.table {
	.red-bg {
		color: white !important;
		background-color: red !important;
	}
	.orange-bg {
		color: white !important;
		background-color: orange !important;
	}
	.lightGreen-bg {
		color: white !important;
		background-color: #00d138 !important;
	}
	.green-bg {
		color: white !important;
		background-color: green !important;
	}
}

.pointer {
	cursor: pointer;
}

.wrap-anywhere {
	overflow-wrap: anywhere;
}

.MuiGrid-grid-xs-true {
	overflow: inherit !important;
}

.react-dropdown-tree-select .node.disabled.tree {
	& > * {
		color: inherit;
		cursor: default;
	}
	& > label > input {
		display: none;
	}
	& > i {
		cursor: pointer;
	}
}

.move-page:only-child {
	display: none
}

.mh-4em {
	max-height: 4em;
}

.error-highlight .form-control:invalid {
	border-color: red;
}

.mw-text {
	min-width: 100px;
}

.mw-200px {
	min-width: 200px;
}

.mw-numeric {
	min-width: 60px;
}

.quote-metainfo .row .col:first-child {
	font-weight: bold;
}

.border-info {
	background-color: #17a2b8 !important;
}

.modified:not(.is-invalid) {
	background-color: #ffc090 !important;
	color: black !important;
}

.agreed .modified:not(.is-invalid):not(.draft) {
	background-color: #c0f7c0 !important;
}

.draft:not(.is-invalid) {
	background-color: #90c0ff !important;
	color: black !important;
}

.purchase-theme {
	border-radius: 6px;
	background-position: center;
	background-size: cover;
	min-height: 200px;
	position: relative;
}

.purchase-theme > .btn {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1908;
}

.purchase-theme > label {
	position: absolute;
	bottom: 0;
	right: 0;
}

.purchase-theme > .d-flex {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 1em;
}

.select__control.h-100 > .select__control {
	height: 100%;
}

.input-group > .select__control {
	flex: 1 1 auto;
}

// The (!) image does not fit in small columns in the item table
.quote-items .form-control.is-invalid, .quote-items .was-validated .form-control:invalid {
	background: inherit;
	padding-right: inherit !important;
}

.swal2-container .swal2-html-container {
	margin: 1em 40px .3em;
}

.material-reset {
	.makeStyles-root-1.MuiGrid-spacing-xs-2 {
		margin: 0;
		width: auto;
		> .MuiGrid-item {
			padding: 0;
			margin-bottom: 0 !important;
			.react-dropdown-tree-select .dropdown-trigger {
				display: block;
				border-color: #ced4da;
			}
		}
	}
}

// Move select arrow into select box to fit into div
.quote-items .react-dropdown-tree-select .dropdown {
	// Applies to both .bottom and .top sub-classes
	.dropdown-trigger.arrow::after {
		margin-left: -20px;
	}
}

.move-buttons .btn {
	font-size: 2em;
	line-height: 0.75;
	padding: 4px;
}

.sticky-left-quote {
	left: 0;
	position: sticky;
	background-color: white;
	z-index: 1;
}

.top-right {
	top: 0;
	right: 0;
}

.pim-box > .MuiGrid-container > .MuiGrid-item {
	max-width: 20%;
	flex-basis: 20%;
}

.pim-box > .MuiGrid-container > .MuiGrid-item:last-of-type {
	max-width: 40%;
	flex-basis: 40%;
}

.pim-item-import input.form-control {
	min-width: 200px;
}
